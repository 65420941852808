<template>
	<div class="transport">
		<keep-alive :include="['workOrderList']"><router-view /></keep-alive>
	</div>
</template>

<script>
export default {
	name: 'transport',
	components: {},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
.transport {
	width: 100%;
	height: 100%;
	overflow-y: auto;
}
</style>
